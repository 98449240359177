html
{ }

*
{ margin: 0;
  padding: 0;}

select {
	width: 150px;
}

body
{ font-family: "trebuchet ms", arial, sans-serif;
  font-size: .84em;
  color: #222;
  line-height: 1.4em;
  background: #c4c4c4;
  width: 90%;
	min-width: 327px;
	max-width: 900px;
  margin: 40px auto 80px auto;}
 
h1, h2, h3, h4, h5, h6 
{ color: #FFA509;
  letter-spacing: 0em;
  padding: 0 0 5px 0;}

h1, h2, h3
{ font: normal 170% 'lucida sans', arial;
  margin: 0 0 15px 0;
  padding: 0 0 5px 0;
  color: #000;}

h2
{ font-size: 130%;
  padding: 9px 0 5px 0;
  color: #555;}

h3
{ font-size: 140%;
  padding: 5px 0 0 0;}

h4, h6
{ color: #009FBC;
  padding: 0 0 5px 0;
  font: normal 110% arial;
  text-transform: uppercase;}

h5, h6
{ color: #888;
  font: normal 95% arial;
  letter-spacing: normal;
  padding: 0 0 15px 0;}

a, a:hover
{ 
	outline: none;
  text-decoration: none;
  /*border-bottom: 1px dashed #000;*/
  color: #000;}

p
{ line-height: 1.5em;
  padding-bottom: 17px;}

a {
text-decoration: none;
color: inherit;
}
a:hover {
text-decoration: none;
color: green;
}

.my_link {
color: inherit;
}
.my_link:hover {
color: red;
}

blockquote
{ margin: 20px 0; 
  padding: 10px 20px 0 20px;
  border: 1px solid #E5E5DB;
  background: #FFF;}

ul
{ margin: 2px 0 12px 0;}

ul li
{ list-style-type: none;
  /*background: url(bullet.png) no-repeat;*/
  margin: 0 0 0 0; 
  padding: 0 0 4px 25px;
  line-height: 1.5em;}

ol
{ margin: 8px 0 22px 0;}

ol li
{ margin: 0 0 11px 20px;}

.left
{ float: left;
  width: auto;
  margin-right: 10px;}

.right
{ float: right; 
  width: auto;
  margin-left: 10px;}

.center
{ display: block;
  text-align: center;
  margin: 20px auto;}

#basic_accordian
{ padding: 0px;
  background: #FFF;}

.accordion_headings
{ padding: 10px 25px 10px 25px;
  background: #E1E1E1;
  color: #111;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #CCC;
  cursor: pointer;
  font: verdana;
  font-size: 150%;
  position: relative;
  z-index: 20;}

.clickable_country
{
	cursor: pointer;
}
.num_of_regions {
	cursor: normal;
	font-weight: normal;
	font-size: 70%;
}
.clickable_city
{
	cursor: pointer;
}

.accordion_headings:hover
{ background: #cccccc;
  color: #FF0000;}

.accordion_child
{ 
	background: #f0f0f0;
	border: '2px solid #008f68';
	marginTop: 10;
	padding: '10px 20px';
	padding: 25px;
}


.header_highlight
{ background: #E1E1E1;
  color: #FF0000;}

.head_photo_container {
	right: 0px;
	top: 0px;
	max-height: 147px;
	max-width: auto;
	position: absolute;
	padding-bottom: 5px;
}

.head_photo_container img {
	max-height: 147px;
	max-width: auto;
}

.header_wrap 
{ 
	display: fixed;
	position: relative;
	width: 100%;
	background: transparent;
}

.jlm_logo_container {
	width: 327px;
	text-align: center;
	left: 0px;
	vertical-align: middle;
	position: relative;
	background: transparent;
}	
img {
	padding-right: 5px;
}
@media screen and (max-width: 710px) {
	.jlm_logo_container {
		width: 100%;
	}
	.header_wrap {
		display: fixed;
		top: auto;
		right: auto;
		text-align: center;
	}
	.head_photo_container {
		position: relative;
		margin-left: auto;
		margin-right: auto;
	}


}

.accordion_section {overflow-wrap: break-word;}
.update_paragraph {
	padding-left: 40px;
	padding-right: 40px;
	font-weight: bold;
}

.response_str {
	white-space: nowrap;
	font-weight: normal;
}
.region_function_location_added {
	color: green;
	white-space: nowrap;
}
.region_function_location_not_added {
	color: red;
	white-space: nowrap;
}
.web_search_results {
	padding-top: 10px;
	overflow: auto;
	font-size: large;
}
.web_search_country {
	padding-bottom: 8px;
	font-weight: bold;
}
.web_search_region {
	font-weight: bold;
	cursor: pointer;
	padding-left: 20px;
	padding-top: 4px;
	padding-bottom: 8px;
	white-space: nowrap;
}
.region_function {
	font-weight: normal;
	cursor: pointer;
	padding-left: 5px;
	white-space: nowrap;
}
.region_function:hover {
	color: red;
	white-space: nowrap;
}

.database_results {
  border: 2px solid #BBB; 
	height: 400px;
	overflow: auto;
}
.my_button {
	background-color:transparent;
	cursor: pointer;
	border-radius:4px;
	padding: 2px 4px 2px 4px;
}

.database_button_wrapper {
	padding-bottom: 5px;
}
.database_buttons_wrapper {
	padding-top: 7px;
	padding-left: 4px;
	padding-bottom: 10px;
}
.results_overview {
	padding-top: 10px;
	padding-bottom: 12px;
}
.my_button:hover {
	color:red;
}
.database_result {
	font-size: large;
	cursor: pointer;
	white-space: nowrap;
	padding-top: 8px;
	padding-left: 5px
}

.database_result:hover {
	font-weight: bold;
	white-space: nowrap;
}
.database_location_result:hover {
	cursor: pointer;
	font-weight: bold;
}
.database_function {
	font-weight: normal;
	padding-top: 8px;
}
.database_function:hover {
	cursor: pointer;
	color: red;
	font-weight: normal;
}
.database_functions_wrapper {
	width: 190px;
	padding-top: 12px;
	padding-left: 10px;
	padding-bottom: 20px;
}
.database_filter_menus {
	padding-top: 4px;
	padding-bottom: 6px;
}
.database_filter_menu {
	padding-left: 4px;
}
.database_tool_activiate_button {
	width: 220px;
}
	
.database_filter { width: 70px; }
.resume_button_png {cursor: pointer;}

#logo
{ width: 70%;
  background: transparent;
  color: #444;
  position: absolute;
  z-index: 10;
  padding-top: 15px;}

#logo h1
{ font: normal 600% 'century gothic', arial;
  padding: 0 0 0 20px;
  margin: 0;
  color: #CCC;}


#footer p
{ letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  padding: 20px;
  color: #444;
  font-size: 90%;}

#footer a, #footer a:hover
{ color: #76786A;
  text-decoration: none;
  border: 0;}

.search
{ color: #5D5D5D; 
  border: 1px solid #BBB; 
  width: 134px; 
  padding: 4px; 
  font: 100% arial, sans-serif;}

.form_settings
{ margin: 15px 0 0 0;}

.form_settings p
{ padding: 0 0 4px 0;}

.form_settings span
{ float: left; 
  width: 200px; 
  text-align: left;}
  
.form_settings input, .form_settings textarea
{ padding: 5px; 
  width: 299px; 
  font: 100% arial; 
  border: 1px solid #E5E5DB; 
  background: #FFF; 
  color: #47433F;}
  
.form_settings .submit
{ font: 100% arial; 
  border: 0; 
  width: 99px; 
  margin: 0 0 0 212px; 
  height: 33px;
  padding: 2px 0 3px 0;
  cursor: pointer; 
  background: #EEE; 
  color: #111;}

.form_settings textarea, .form_settings select
{ font: 100% arial; 
  width: 299px;}

.form_settings select
{ width: 310px;}

.form_settings .checkbox
{ margin: 4px 0; 
  padding: 0; 
  width: 14px;
  border: 0;
  background: none;}

.separator
{ width: 100%;
  height: 0;
  border-top: 1px solid #D9D5CF;
  border-bottom: 1px solid #FFF;
  margin: 0 0 20px 0;}
  
table
{ margin: 10px 0 30px 0;}

table tr th, table tr td
{ background: #EFEFEF;
  color: #111;
  padding: 7px 4px;
  text-align: left;}
  
table tr td
{ background: #F8F8F8;
  color: #47433F;
  border-top: 1px solid #FFF;}
